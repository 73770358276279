<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="contacts-mobile-title container  d-md-none">{{ $t('Contacts') }}</div>
  <div id="main-container" class="">

    <div class="container d-md-flex">

      <div class="col-md-5   contacts-left-wrapper">

        <div class="">
          <div class="">
            <div class="need-help-title"> {{ $t('do-you-need-help') }}</div>
            <div class="need-help-subtitle"> {{ $t('send-inquiry-or-call') }}</div>
          </div>
          <div class="contact-title-item">
            <div class=""> Email:</div>
            <div class=""> {{ contact_details.email }}</div>
          </div>
          <div class="contact-title-item no-border">
            <div class=""> {{ $t('location') }}:</div>
            <div class=""> {{ contact_details.location }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-7  contacts-right-wrapper">
        <div class="contacts-right-title d-none d-md-block">{{ $t('contacts') }}</div>
        <div class="row ">
          <div class="col-md-4 input-items">
            <InputField :inputtype="'text'" :label="$t('your-name')"/>
          </div>
          <div class="col-md-4 input-items">
            <InputField :inputtype="'number'" :label="$t('your-number')"/>
          </div>
          <div class="col-md-4 input-items">
            <InputField :inputtype="'email'" :label="'Email'"/>
          </div>
        </div>
        <div class="row input-items text-area">
          <TextArea :label="$t('your-message')"/>
        </div>
        <div class="col-md-5">
          <Button :text="$t('send')" :size="'medium'" :theme="'solid-orange'"/>
        </div>
      </div>
    </div>

  </div>


  <div>
    <Footer/>
  </div>
</template>

