import Button from "@/components/Common/Buttons/Button/Button";
import AboutButton from "@/components/Common/Buttons/AboutButton/AboutButton";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import InputField from "@/components/Common/Inputs/Input/Input";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import PrinceRangeSlider from "@/components/Components/PriceRangeSlider/PriceRangeSlider";
import MarketplaceAd from "@/components/Components/MarketplaceAd/MarketplaceAd";
import PropertyAd from "@/components/Components/PropertyAd/PropertyAd";
import MarketplaceSortFilter from "@/views/MarketplaceCategoryPage/MarketplaceSortFilter/MarketplaceSortFilter";


export default {
    name: 'ContactPage',
    components: {

        Button,
        CheckBox,
        PrinceRangeSlider,
        MarketplaceAd,
        PropertyAd,
        AboutButton,
        MarketplaceSortFilter,
        InputField,
        TextArea
    },
    data() {
        return {
            filterContainerActive: false,
            activeGrid: 'properties',
            contact_details: {
                phone: '0888191616',
                email: "office@sakvartirantite.com",
                technical_support_phone: '0888 88 88 88',
                location: this.$t('company-location'),
            },


        }
    },
    methods: {
        toggleFilterContainer() {
            this.filterContainerActive = !this.filterContainerActive
        }
    },
    mounted() {
    },
}
